<template>
  <div style="height: 100%">
    <v-icon
      style="position: fixed; top: 52px"
      :style="`right:${rightPosition}px;`"
      @click="toggleMute"
      >{{ mute ? "mdi-bell-off-outline" : "mdi-bell-outline" }}</v-icon
    >

    <v-autocomplete
      style="position: fixed; top: 45px; width: 10%"
      :style="`right:${rightPosition + 100}px;`"
      solo
      dense
      flat
      outlined
      placeholder="Hotel"
      :items="objects.list"
      item-value="id"
      item-text="name"
      v-model="object"
      @change="changeObject"
    >
      <template v-slot:selection="{ item }">
                        <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
      </template>
      <template v-slot:item="{ item }">
                        <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
      </template>
    </v-autocomplete>
    <div style="height: 100%">
      <div style="display: flex; justify-content: center">
        <div
          style="
            display: flex;
            border-radius: 5px;
            border: 2px solid rgb(238, 238, 238);
            padding: 15px;
          "
        >
          <div class="leftSidebar" style="width: 350px">
            <div class="leftSidebarHeader">
              <div class="leftSidebarHeaderTitle">Order list</div>
            </div>
            <div class="leftSidebarBody">
              <v-tabs hide-slider>
                <v-tab class="leftSidbarBodyTab"> All </v-tab>
                <v-tab class="leftSidbarBodyTab"> New </v-tab>
                <v-tab class="leftSidbarBodyTab"> In process </v-tab>
                <v-tab class="leftSidbarBodyTab"> Finished </v-tab>
                <v-tab class="leftSidbarBodyTab"> Deleted </v-tab>
                <v-tab class="leftSidbarBodyTab"> Canceled </v-tab>

                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderList"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border:1px solid ${orderStatusColor(
                      order
                    )};cursor:pointer;border-left:10px solid ${orderStatusColor(
                      order
                    )};background:${
                      selectedOrder && order.id === selectedOrder.id
                        ? 'rgb(238,238,238)'
                        : '#fff'
                    };`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      border-radius: 15px;
                      padding: 15px;
                      position: relative;
                      align-items: center;
                    "
                  >
                    <div
                      style="
                        position: absolute;
                        top: 0;
                        right: 5px;
                        font-size: 14px;
                      "
                    >
                      {{ orderStatusLabel(order.order_status) }}
                    </div>
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderList.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderNew"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border:1px solid ${orderStatusColor(
                      order
                    )};border-left:10px solid ${orderStatusColor(order)};background:${
                      selectedOrder && order.id === selectedOrder.id
                        ? 'rgb(238,238,238)'
                        : '#fff'
                    };`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      background: #fff;
                      border-radius: 15px;
                      padding: 15px;
                      align-items: center;
                    "
                  >
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderNew.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderProcess"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border:1px solid ${orderStatusColor(order)};border-left:10px solid ${orderStatusColor(order)};background:${
                      selectedOrder && order.id === selectedOrder.id
                        ? 'rgb(238,238,238)'
                        : '#fff'
                    };`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      background: #fff;
                      border-radius: 15px;
                      padding: 15px;
                      align-items: center;
                    "
                  >
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderProcess.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderComplete"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border:1px solid ${orderStatusColor(order)};border-left:10px solid ${orderStatusColor(
                      order
                    )};background:${
                      selectedOrder && order.id === selectedOrder.id
                        ? 'rgb(238,238,238)'
                        : '#fff'
                    };`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      background: #fff;
                      border-radius: 15px;
                      padding: 15px;
                      align-items: center;
                    "
                  >
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderComplete.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderDeleted"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border:1px solid ${orderStatusColor(
                      order
                    )};border-left:10px solid ${orderStatusColor(
                      order
                    )};background:${
                      selectedOrder && order.id === selectedOrder.id
                        ? 'rgb(238,238,238)'
                        : '#fff'
                    };`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      background: #fff;
                      border-radius: 15px;
                      padding: 15px;
                      align-items: center;
                    "
                  >
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderDeleted.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
                <v-tab-item class="leftSidebarBodyTabItem">
                  <div
                    v-for="order in orderCanceled"
                    @click="selectOrder(order)"
                    :key="order.id"
                    :style="`border-left:10px solid ${orderStatusColor(
                      order
                    )};`"
                    style="
                      display: flex;
                      margin-bottom: 15px;
                      background: #fff;
                      border-radius: 15px;
                      padding: 15px;
                      align-items: center;
                    "
                  >
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-bottom: 10px; font-size: 20px">
                        Order #{{ order.id }}
                        <span v-if="order.room_number"
                          >from Room #{{ order.room_number }}</span
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px">mdi-clock</v-icon
                        >{{ changeFormatDate(order.created_at) }}
                      </div>
                    </div>
                    <div class="col-4" style="font-weight: bold">
                      {{ summOfPrices(order.order_data) }}
                      {{ landing.advanced.global.currency }}
                    </div>
                  </div>
                  <div
                    v-if="orderDeleted.length === 0"
                    style="
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      color: #ccc;
                    "
                  >
                    Order list empty!
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
          </div>
          <div style="height: 100%; width: 600px; padding: 15px">
            <div v-if="selectedOrder">
              <div class="popup__title" style="margin-top: 15px">
                Order Detail # {{ selectedOrder.id }}
              </div>
              <v-tabs right>
                <v-tab>Info</v-tab>
                <v-tab>Customer</v-tab>
                <v-tab>History</v-tab>
                <v-tab-item style="padding-top: 15px">
                  <div>
                    <div
                      style="margin-bottom: 30px"
                      v-if="selectedOrder.table_number"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          margin-bottom: 15px;
                        "
                      >
                        <div>Client table</div>
                        <div>{{ selectedOrder.table_number }}</div>
                      </div>
                    </div>

                    <div
                      style="margin-bottom: 30px"
                      v-if="selectedOrder.hidden_id"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          margin-bottom: 15px;
                        "
                      >
                        <div>Hidden id</div>
                        <div>{{ selectedOrder.hidden_id }}</div>
                      </div>
                    </div>

                    <div
                      style="margin-bottom: 30px"
                      v-if="selectedOrder.hidden_title"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          margin-bottom: 15px;
                        "
                      >
                        <div>Hidden title</div>
                        <div>{{ selectedOrder.hidden_title }}</div>
                      </div>
                    </div>

                    <div>
                      <ul
                        style="
                          font-weight: normal;
                          font-size: 15px;
                          margin-bottom: 30px;
                          padding-left: 0;
                        "
                      >
                        <li
                          v-for="(data, index) in selectedOrder.order_data"
                          :key="index + 'index' + index"
                          style="
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                          "
                        >
                          <div
                            v-for="(item, itemIndex) in data"
                            :key="item + 'index' + itemIndex"
                            style="
                              display: flex;
                              flex-direction: column;
                              width: 100%;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              padding-bottom: 5px;
                            "
                          >
                            <div
                              v-if="item.radio === 'checkbox' && item.text"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ item.price }} {{ item.currency }}</div>
                            </div>
                            <div
                              v-else-if="item.radio === 'text'"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ item.price }} {{ item.currency }}</div>
                            </div>
                            <div
                              v-else-if="item.radio === 'number'"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>
                                {{ item.count }}*{{ item.price }}
                                {{ item.currency }} =
                                {{ item.count * +item.price }}
                                {{ item.currency }}
                              </div>
                            </div>
                            <div
                              v-else-if="item.radio === 'date'"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ formatDateDetail(item.text) }}</div>
                            </div>
                            <div
                              v-else-if="item.radio === 'time'"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ item.text }}</div>
                            </div>
                            <div
                              v-else-if="item.radio === 'radio'"
                              style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                              "
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ item.price }} {{ item.currency }}</div>
                            </div>
                            <div
                              v-else-if="item.radio === 'notice'"
                              style="width: 100%"
                            >
                              <div>{{ item.label }}</div>
                              <div>{{ item.text }}</div>
                            </div>
                          </div>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                              margin-top: 15px;
                              font-weight: bold;
                            "
                          >
                            <div>Total</div>
                            <div>
                              {{ totalOrder(selectedOrder.order_data) }}
                              {{ landing.advanced.global.currency }}
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div style="margin-bottom: 30px">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 15px;
                          "
                        >
                          <div>Change order status</div>
                          <v-autocomplete
                            style="margin: 0; max-width: 200px"
                            dense
                            hide-details
                            outlined
                            placeholder="Order status"
                            :items="order_status_types"
                            v-model="selectedOrder.order_status"
                            @change="saveChanges(selectedOrder)"
                          ></v-autocomplete>
                        </div>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          "
                        >
                          <div>Order order pay status</div>
                          <v-autocomplete
                            style="margin: 0; max-width: 200px"
                            dense
                            hide-details
                            outlined
                            placeholder="Pay status"
                            :items="order_pay_types"
                            v-model="selectedOrder.pay_status"
                            @change="saveChanges(selectedOrder)"
                          ></v-autocomplete>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style="
                          text-align: center;
                          font-size: 20px;
                          margin-bottom: 15px;
                        "
                      >
                        Notice
                      </div>
                      <v-textarea
                        outlined
                        solo
                        dense
                        style="width: 100%"
                        v-model="selectedOrder.comment"
                      >
                      </v-textarea>
                      <v-btn
                        outlined
                        dense
                        small
                        color="primary"
                        @click="saveChanges(selectedOrder)"
                        >Save notice</v-btn
                      >
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item style="padding-top: 15px">
                  <div>
                    <ul
                      style="
                        font-weight: normal;
                        font-size: 15px;
                        margin-bottom: 30px;
                        padding-left: 0;
                      "
                    >
                      <li
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        "
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                          "
                        >
                          <div
                            v-if="selectedOrder.first_name"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>First name</div>
                            <div>{{ selectedOrder.first_name }}</div>
                          </div>
                          <div
                            v-if="selectedOrder.last_name"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>Last name</div>
                            <div>{{ selectedOrder.last_name }}</div>
                          </div>
                          <div
                            v-if="selectedOrder.room_number"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>Room number</div>
                            <div>{{ selectedOrder.room_number }}</div>
                          </div>
                          <div
                            v-if="selectedOrder.phone_number"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>Phone number</div>
                            <div>{{ selectedOrder.phone_number }}</div>
                          </div>
                          <div
                            v-if="selectedOrder.email"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>Email</div>
                            <div>{{ selectedOrder.email }}</div>
                          </div>
                          <div
                            v-if="selectedOrder.pay_type"
                            style="
                              padding-bottom: 5px;
                              border-bottom: 1px solid rgb(238, 238, 238);
                              width: 100%;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <div>Pay type</div>
                            <div>{{ selectedOrder.pay_type }}</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-tab-item>
                <v-tab-item style="padding-top: 15px">
                  <v-data-table
                    :headers="headersHistory"
                    hide-default-footer
                    :items="selectedOrder.order_history"
                  >
                    <template v-slot:body="{ items }">
                      <tbody v-if="items.length">
                        <tr v-for="item in items" :key="item.id">
                          <td class="d-block d-sm-table-cell"
                            style="text-align: center"
                            v-if="!item.user && !item.order_user"
                          >
                            <img
                              style="
                                height: 30px;
                                width: 30px;
                                border-radius: 15px;
                              "
                              :src="
                                require('@/assets/icons/user_placeholder.svg')
                              "
                              alt=""
                            />
                          </td>
                          <td class="d-block d-sm-table-cell"
                            style="text-align: center"
                            v-if="!item.user && !item.order_user"
                          ></td>

                          <td class="d-block d-sm-table-cell" style="text-align: center" v-if="item.user">
                            <img
                              style="
                                height: 30px;
                                width: 30px;
                                border-radius: 15px;
                              "
                              :src="
                                item.user.avatar
                                  ? `${VUE_APP_STORAGE_URL + item.user.avatar}`
                                  : require('@/assets/icons/user_placeholder.svg')
                              "
                              alt=""
                            />
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center" v-if="item.user">
                            {{ item.user.name ? item.user.name : "" }}
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center" v-if="item.order_user">
                            <img
                              style="
                                height: 30px;
                                width: 30px;
                                border-radius: 15px;
                              "
                              :src="
                                item.order_user.avatar
                                  ? `${
                                      VUE_APP_STORAGE_URL +
                                      item.order_user.avatar
                                    }`
                                  : require('@/assets/icons/user_placeholder.svg')
                              "
                              alt=""
                            />
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center" v-if="item.order_user">
                            {{
                              item.order_user.first_name
                                ? item.order_user.first_name
                                : ""
                            }}
                            {{
                              item.order_user.last_name
                                ? item.order_user.last_name
                                : ""
                            }}
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center">
                            {{ formatDateDetail(item.created_at) }}
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center">
                            {{ formatTimeDetail(item.created_at) }}
                          </td>
                          <td class="d-block d-sm-table-cell" style="text-align: center">
                            {{ changeStatus(item.old_status) }}
                            {{ item.old_status ? "&#10140;" : "" }}
                            {{ changeStatus(item.new_status) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td class="d-block d-sm-table-cell" colspan="5" style="text-align: center">
                            This order do not have some updates
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table></v-tab-item
                >
              </v-tabs>
            </div>
            <div
              v-else
              style="
                display: flex;
                height: 900px;
                padding-top: 190px;
                width: 600px;
                color: #ccc;
                justify-content: center;
                align-items: center;
              "
            >
              Choose order
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupDelete
      ref="PopupDelete"
      title="Are your sure about delete order?"
      @confirm="deleteOrderConfirm"
    />
    <PopupSuccess ref="PopupSuccess" title="Success" content="Changed successfull" />
  </div>
</template>

<script>
import moment from "moment";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import { mapState } from "vuex";
import sound from "@/assets/audio.mp3";

export default {
  components: {
    PopupDelete,
    PopupSuccess,
  },
  data() {
    return {
      titleName: "",
      object: "",
      rightPosition: 0,
      mute: false,
      orderList: [],
      selectedOrder: null,
      headers: [
        { text: "Avatar", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Date", align: "center", sortable: true },
        { text: "Time", align: "center", sortable: true },
        { text: "Status", align: "center", sortable: true },
      ],
      headersHistory: [
        { text: "Avatar", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Date", align: "center", sortable: true },
        { text: "Time", align: "center", sortable: true },
        { text: "Status", align: "center", sortable: true },
      ],
      deleteOrderId: null,
      order_status_types: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "In processing",
          value: "is_processing",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
        {
          text: "Canceled",
          value: "canceled",
        },
      ],
      order_pay_types: [
        {
          text: "Not paid",
          value: "not paid",
        },
        {
          text: "Paid",
          value: "paid",
        },
      ],
    };
  },
  async mounted() {
    if (!this.$store.state.profile.name) {
      await this.$store.dispatch("getProfile");
    }
    if (this.$store.state.profile.is_reception) {
      this.titleName = "Reception Dashboard";
      await this.$store.dispatch("objects/getList");
      this.object = this.objects.list[0].id;
      const { data } = await this.$axios.get(
        `${process.env.VUE_APP_API_URL}order?hotel_id=${this.object}`
      );
      this.orderList = data.reverse();

      setInterval(async () => {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}order?hotel_id=${this.object}`
        );
        if (this.orderList.length !== data.length && !this.mute) {
          const audio = new Audio(sound);
          await audio.play();
        }
        this.orderList = data.reverse();
      }, 30000);
    } else {
      this.titleName = "Welcome";
    }
    document.title = this.titleName;
    this.rightPosition = document.getElementById("userName").offsetWidth + 150;
  },
  name: "Welcome.vue",
  computed: {
    ...mapState(["objects"]),
    ...mapState(["landing"]),
    VUE_APP_STORAGE_URL() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    orderNew() {
      return this.orderList.filter((x) => x.order_status === "new");
    },
    orderProcess() {
      return this.orderList.filter((x) => x.order_status === "is_processing");
    },
    orderComplete() {
      return this.orderList.filter((x) => x.order_status === "complete");
    },
    orderDeleted() {
      return this.orderList.filter((x) => x.order_status === "deleted");
    },
    orderCanceled() {
      return this.orderList.filter((x) => x.order_status === "canceled");
    },
  },

  methods: {
    orderStatusLabel(status) {
      const candidate = this.order_status_types.find((x) => x.value === status);
      if (candidate) {
        return candidate.text;
      } else {
        return status;
      }
    },
    async getToken() {
      // if('Notification' in window){
      //   await window.Notification.requestPermission();
      //   if(window.Notification.permission === 'granted'){
      //     const messaging = getMessaging(this.app);
      //     const token = await messaging.firebaseDependencies.installations.getToken();
      //     if(token){
      //       console.log()
      //     }
      //   }
      // }else{
      //   alert('You browser not supporting notifications');
      // }
      //   this.$messaging().getToken({ vapidKey: <KEY> })
      // .then((currentToken) => {
      //     if (currentToken) {
      //       console.log('client token', currentToken)
      //     } else {
      //       console.log('No registration token available. Request permission to generate one.');
      //     }
      //   }).catch((err) => {
      //     console.log('An error occurred while retrieving token. ', err);
      //   })
    },
    toggleMute() {
      this.mute = !this.mute;
    },
    changeStatus(status) {
      const candidate = this.order_status_types.find((x) => status === x.value);
      return candidate ? candidate.text : "";
    },
    totalOrder(arr) {
      let total = 0;
      let currency = "";
      for (let i = 0; i < arr.length; i++) {
        for (let index = 0; index < arr[i].length; index++) {
          let item = arr[i][index];
          if (item.radio === "text") {
            total += +item.price;
            currency = item.currency;
          } else if (item.radio === "number") {
            total += +item.price * +item.count;
            currency = item.currency;
          } else if (item.radio === "checkbox") {
            total += +item.price;
            currency = item.currency;
          } else if (item.radio === "radio") {
            total += +item.price;
            currency = item.currency;
          }
        }
      }
      return total.toFixed(2) + " ";
    },
    async deleteOrderConfirm() {
      await this.$axios.delete(
        `${process.env.VUE_APP_API_URL}order/${this.selectedOrder.id}`
      );
      this.orderList.splice();
    },
    summOfPrices(arr) {
      let total = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let index = 0; index < arr[i].length; index++) {
          let item = arr[i][index];
          if (item.radio === "text") {
            total += +item.price;
          } else if (item.radio === "number") {
            total += +item.price * +item.count;
          } else if (item.radio === "checkbox") {
            total += +item.price;
          }
        }
      }
      return total.toFixed(2);
    },
    async saveChanges(item) {
      const { data } = await this.$axios.put(
        `${process.env.VUE_APP_API_URL}order/${item.id}`,
        item
      );
      const candidate = this.orderList.find((x) => x.id === item.id);
      if (candidate) {
        const candidateIndex = this.orderList.indexOf(candidate);
        this.orderList.splice(candidateIndex, 1, data);
      }
      this.$refs.PopupSuccess.open();
    },
    formatDateDetail(date) {
      return moment(date).format("DD.MM.yyyy");
    },
    formatTimeDetail(date) {
      return moment(date).format("HH:mm");
    },
    selectOrder(order) {
      this.selectedOrder = JSON.parse(JSON.stringify(order));
    },
    orderStatusColor(order) {
      if (order.order_status === "new") {
        return "rgb(242,242,240)";
      } else if (order.order_status === "is_processing") {
        const diff =
          +moment().format("x") - +moment(order.updated_at).format("x");
        const min = 60000;
        if (diff < 5 * min) {
          return `rgba(255,0,0,0.2)`;
        }
        if (diff < 15 * min) {
          return "rgb(255,0,0,0.4)";
        }
        if (diff < 30 * min) {
          return "rgb(255,0,0,0.6)";
        }
        if (diff < 60 * min) {
          return "rgb(255,0,0,0.8)";
        } else {
          return "rgb(255,0,0,1)";
        }
      } else if (order.order_status === "deleted") {
        return "rgb(0,0,0)";
      } else if (order.order_status === "completed") {
        return "rgb(12,127,23)";
      } else {
        return "rgb(252,13,151)";
      }
    },
    changeFormatDate(date) {
      return moment(date).fromNow();
    },
      async changeObject(id) {
      await this.$router.push({
        name: "Constructor",
        params: { id: id, lang: this.$route.params.lang },
      });
      this.getLanding(id);
    },
  },
};
</script>

<style lang="scss">
.leftSidebar {
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    &Title {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
.leftSidebar .v-tab {
  border-radius: 15px;
}
.leftSidebarBodyTabItem {
  height: 900px;
  overflow-y: auto;
  padding-right: 15px;
}
.leftSidebar .v-slide-group__prev {
  display: none;
}
.leftSidebar .v-slide-group__next {
  display: none;
}
.leftSidebar .v-tab.v-tab--active {
  color: #fff;
  background: rgb(58, 92, 202);
}
.leftSidebar .v-tabs-bar {
  margin-bottom: 15px;
}
.phone {
  background: rgb(17, 127, 23);
  cursor: pointer;
}
.phone:hover {
  background-color: rgb(17, 175, 23);
}

.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
</style>
